export const twicpicsPath = (url?: string, { isLibrary = false }: { isLibrary?: boolean } = {}) => {
  if (!url) {
    return ''
  }

  let twipicPath = url
  if (!twipicPath.startsWith('/')) {
    twipicPath = `/${twipicPath}`
  }

  const validTwicpicsFolderRegExp = new RegExp(/^\/(library|rental)/)
  if (!validTwicpicsFolderRegExp.test(twipicPath)) {
    twipicPath = `/${isLibrary ? 'library' : 'rental'}${twipicPath}`
  }

  return twipicPath
}
